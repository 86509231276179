<template>
	<div class="setting">
		<div class="title">
			{{ $route.meta.title }}
			<i @click="showCourse = !showCourse" class="iconfont icon-question-circle"></i>
		</div>
		<div class="course" v-show="showCourse">
			<p><span class="black">1、为保证存档功能正常使用，请先完成此页配置。</span></p>
			<p><span class="black">2、开通会话存档功能需在企业微信管理后台进行，请先开通后再进行参数配置。</span></p>
			<p><span class="black">3、切换企业后，此页配置需重新进行配置。</span></p>
		</div>
		<div class="main" v-loading="loading">
			<el-form label-position="left" label-width="170px" :model="form" :rules="rules" ref="form">
				<div class="config-title">
					<span class="icon"></span>
					<span class="name">开通企业微信会话存档</span>
				</div>
				<div class="custom-message">使用会话存档需在企业微信后台开通会话存档功能，配置完成后，才可正常使用会话存档相关功能</div>
				<div><el-button style="padding: 0;margin-bottom: 20px;margin-left: 10px;" type="text" @click="handleHelp(20)">如何开通</el-button></div>
				<div class="config-title">
					<span class="icon"></span>
					<span class="name">会话存档配置</span>
				</div>
				<div class="custom-message">请将以下配置与企业微信后台配置保持一致，否则将致使功能无法正常使用</div>
				<el-form-item label="企业ID" prop="wxk_id"><el-input disabled style="width: 600px;" readonly size="small" v-model="form.wxk_id"></el-input></el-form-item>
				<el-form-item label="可信IP地址" required>
					<el-input style="width: 510px;margin-right: 10px;" disabled readonly size="small" v-model="ip"></el-input>
					<el-button size="small" class="btn-blue" @click="$copy($event, ip)">一键复制</el-button>
					<i class="ri-question-line help" @click="handleHelp(21)"></i>
				</el-form-item>
				<el-form-item label="会话存档Secret" prop="chat_secret">
					<el-input style="width: 600px;" v-model="form.chat_secret" size="small"></el-input>
					<i class="ri-question-line help" @click="handleHelp(22)"></i>
				</el-form-item>
				<el-form-item label="会话存档RSA2048秘钥" prop="chat_secret_key">
					<el-input
						style="margin-top: 10px;width: 600px;"
						type="textarea"
						:autosize="{ minRows: 15, maxRows: 15 }"
						resize="none"
						v-model="form.chat_secret_key"
						size="small"
					></el-input>
					<i class="ri-question-line help top" @click="handleHelp(23)"></i>
				</el-form-item>
				<el-form-item>
					<el-button v-show="!loading" style="margin-top: 10px;" size="small" class="btn-blue" @click="handleSaveConfig">保存配置</el-button>
					<el-button v-show="loading" style="margin-top: 10px;cursor: default;" size="small" class="btn-blue">保存配置</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
import { reqGetWechatList, reqSynCorpChat, reqAddWechat, reqGetIp } from '@/api/index';
export default {
	data() {
		return {
			showCourse: false,
			form: {
				wxk_id: '',
				chat_secret: '',
				chat_secret_key: ''
			},
			ip: '',
			loading: false,
			rules: {
				wxk_id: [{ required: true, message: '请输入企业ID', trigger: 'blur' }],
				chat_secret: [{ required: true, message: '请输入会话存档Secret', trigger: 'blur' }],
				chat_secret_key: [{ required: true, message: '请输入会话存档RSA2048秘钥', trigger: 'blur' }]
			}
		};
	},
	created() {
		this.loading = true;
		Promise.all([
			new Promise(resolve => {
				this.getIp(resolve);
			}),
			new Promise(resolve => {
				this.getWechatList(resolve);
			})
		]).then(res => {
			this.loading = false;
		});
	},
	methods: {
		// 获取当前企业配置
		getWechatList(resolve) {
			reqGetWechatList({
				page: 1,
				limit: 100
			}).then(res => {
				this.form =
					res.data.filter(val => {
						return val.wxk_id == this.$store.state.setting.corp_id && val.wxk_id;
					})[0] || {};
				resolve !== undefined ? resolve() : '';
			});
		},
		// 获取Ip地址
		getIp(resolve) {
			reqGetIp({}).then(res => {
				this.ip = res.data;
				resolve !== undefined ? resolve() : '';
			});
		},
		// 编辑会话存档
		editWechatList() {
			reqAddWechat(this.form).then(res => {
				this.$message.success(res.msg);
			});
		},
		// 保存配置
		handleSaveConfig() {
			this.$refs['form'].validate(valid => {
				if (valid) {
					this.$message({
						center: true,
						message: '保存配置中...',
						iconClass: 'el-icon-loading',
						duration: 0
					});
					reqSynCorpChat({ msg: 1 }).then(res => {
						this.editWechatList();
					});
				} else {
					return false;
				}
			});
		},
		// 帮助
		handleHelp(domIndex) {
			this.$bus.$emit('showHelp', domIndex);
		}
	}
};
</script>

<style lang="less" scoped>
.setting {
	width: 100%;
	position: relative;
	overflow: hidden;

	.help {
		vertical-align: middle;
		color: #666;
		font-size: 20px;
		cursor: pointer;
		margin-left: 6px;
		&.top {
			vertical-align: top;
		}
	}

	.main {
		margin-top: 15px;

		.config-title {
			width: calc(100% - 24px);
			height: 32px;
			background: #e5ebfb;
			border-radius: 5px 5px 0px 0px;
			display: flex;
			align-items: center;
			padding: 0px 12px;
			margin-bottom: 15px;

			.icon {
				width: 3px;
				height: 16px;
				background: #6881ec;
				border-radius: 2px;
				margin-right: 6px;
			}

			.name {
				font-size: 14px;
				font-weight: bold;
				color: #6881ec;
			}
		}

		.custom-message {
			margin-bottom: 15px;
		}
	}
}
</style>
